import $ from 'jquery';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import '@popperjs/core';
import 'moment';
/* eslint-disable import/no-unresolved */
import 'channels';
import 'controllers';
/* eslint-disable import/no-unresolved */
import '../application/stylesheets/application.css.scss';
import '../stylesheets/application.scss';
import { Application } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

window.$ = $;
window.jQuery = $;
if (global) global.jQuery = $;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const application = Application.start();

// Configure Stimulus development experience
application.debug = process.env.RAILS_ENV === 'development';
application.consumer = consumer;
window.Stimulus = application;

export default application;
